import ReactECharts from "echarts-for-react";
import ReactDOMServer from "react-dom/server";
import { memo } from "react";
import dayjs from "dayjs";
import { commonChartOptions } from "../../assets/config/commonChartOptions";

interface Props {
  chartRef: React.RefObject<ReactECharts>;
}

const MeasurementsChart = ({ chartRef }: Props) => {
  const option = {
    title: [
      commonChartOptions.noDataTitle,
      { ...commonChartOptions.title, text: "Energy Consumption" },
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      valueFormatter: (value: number) => value.toFixed(2) + " Wh",
    },
    animation: false,
    xAxis: {
      type: "time",
      axisLabel: {
        hideOverlap: true,
      },
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: true,
      },
    },
    toolbox: {
      ...commonChartOptions.toolbox,
      feature: {
        mark: { show: true },
        magicType: { show: true, type: ["bar", "line"] },
        dataView: {
          show: true,
          readOnly: true,
          optionToContent: (option: any) => {
            // returns the HTML convertion of JSX element
            return ReactDOMServer.renderToStaticMarkup(
              <table className="text-center w-full">
                <tbody>
                  <tr className="border-b-[1px] border-border">
                    <th className="font-medium w-1/3">Time</th>
                    {option.series.map((serie: any) => (
                      <th className="font-medium w-1/3" key={serie.name}>
                        {serie.name}
                      </th>
                    ))}
                  </tr>
                  {option.series[0].data.map(
                    (data: [string, number], index: number) => (
                      <tr className="border-b-[1px] border-border" key={index}>
                        <td>{dayjs(data[0]).format("L LT")}</td>
                        <td>{option.series[0].data[index][1]}</td>
                        <td>{option.series[1].data[index][1]}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            );
          },
        },
        saveAsImage: { show: true },
      },
    },
    legend: {
      ...commonChartOptions.legend,
      formatter(name: string) {
        return name.split(" ")[0]; // Removes "Energy" word from seriNames array.
      },
    },
    grid: {
      bottom: 0,
      left: 0,
      right: 0,
      containLabel: true,
    },
    series: [
      {
        name: "Active Energy",
        type: "bar",
      },
      {
        name: "Reactive Energy",
        type: "bar",
      },
    ],
    color: commonChartOptions.color,
    textStyle: commonChartOptions.textStyle,
  };

  return (
    <ReactECharts
      style={{ width: "100%", height: "100%" }}
      ref={chartRef}
      option={option}
    />
  );
};

export default memo(MeasurementsChart);
