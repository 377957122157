import {
  EChartsOption,
  SunburstSeriesOption,
  TreemapSeriesOption,
} from "echarts";
import ReactECharts from "echarts-for-react";
import { Fragment, memo } from "react";
import { commonChartOptions } from "../../assets/config/commonChartOptions";
import ReactDOMServer from "react-dom/server";

interface Props {
  chartRef: React.RefObject<ReactECharts>;
}

const CircuitEnergyConsumptionChart = ({ chartRef }: Props) => {
  const sunburstSerie: SunburstSeriesOption = {
    type: "sunburst",
    name: "Active Energy",
    center: ["50%", "55%"],
    radius: [0, "90%"],
    label: {
      rotate: 0,
    },
    labelLayout: {
      hideOverlap: true,
    },
    itemStyle: {
      borderWidth: 2,
    },
  };

  const treemapSerie: TreemapSeriesOption = {
    type: "treemap",
    name: "Active Energy",
    width: "100%",
    height: "88%",
    top: "bottom",
    roam: false,
    upperLabel: {
      show: true,
      height: 30,
      color: "#fff",
    },
    breadcrumb: {
      top: "90%",
    },
    itemStyle: {
      borderColor: "#fff",
    },
    levels: [
      {
        upperLabel: {
          show: false,
        },
      },
      {
        itemStyle: {
          borderColor: "#555",
          borderWidth: 5,
          gapWidth: 5,
        },
        emphasis: {
          itemStyle: {
            borderColor: "#888",
          },
        },
      },
      {
        colorSaturation: [0.35, 0.5],
        itemStyle: {
          borderWidth: 5,
          gapWidth: 5,
          borderColorSaturation: 0.6,
        },
      },
    ],
  };

  const option = {
    title: [
      commonChartOptions.noDataTitle,
      { ...commonChartOptions.title, text: "Consumption Distribution" },
    ],
    textStyle: commonChartOptions.textStyle,
    animation: false,
    tooltip: {
      trigger: "item",
      triggerOn: "mousemove",
      valueFormatter: (value: number) => value.toFixed(2) + " Wh",
    },
    toolbox: {
      ...commonChartOptions.toolbox,
      feature: {
        mark: { show: true },
        mySunburstSwitch: {
          show: true,
          title: "Switch to Sunburst",
          icon: "M16,31.36c-4.103,0-7.96-1.598-10.861-4.499c-0.141-0.141-0.141-0.369,0-0.51l3.289-3.288 c-1.652-1.771-2.693-4.118-2.782-6.704H1c-0.199,0-0.36-0.161-0.36-0.36c0-4.103,1.597-7.959,4.498-10.86 c0.027-0.027,0.059-0.05,0.093-0.067c0.185-0.093,0.227-0.107,3.704,3.357c1.77-1.653,4.119-2.694,6.705-2.783V1 c0-0.199,0.161-0.36,0.36-0.36c8.47,0,15.36,6.89,15.36,15.36c0,0.199-0.161,0.36-0.36,0.36h-4.646 c-0.187,5.427-4.566,9.807-9.994,9.994V31C16.36,31.199,16.199,31.36,16,31.36z M5.906,26.604c2.646,2.523,6.078,3.944,9.734,4.032 v-4.282c-2.585-0.089-4.934-1.129-6.704-2.781L5.906,26.604z M16.36,21.348v4.286c5.031-0.186,9.088-4.243,9.274-9.274h-4.286 C21.17,19.029,19.029,21.17,16.36,21.348z M9.446,23.063c1.64,1.522,3.807,2.482,6.194,2.57v-4.286 c-1.208-0.08-2.307-0.562-3.166-1.313L9.446,23.063z M6.367,16.36c0.088,2.387,1.048,4.555,2.57,6.194l3.029-3.028 c-0.751-0.858-1.233-1.958-1.314-3.166H6.367z M16,11.36c-2.559,0-4.64,2.082-4.64,4.64s2.082,4.64,4.64,4.64s4.64-2.081,4.64-4.64 S18.559,11.36,16,11.36z M26.354,15.64h4.282C30.447,7.853,24.147,1.553,16.36,1.364v4.282C21.787,5.833,26.167,10.213,26.354,15.64 z M21.348,15.64h4.286c-0.186-5.03-4.243-9.088-9.274-9.273v4.285C19.029,10.83,21.17,12.971,21.348,15.64z M6.367,15.64h4.285 c0.081-1.208,0.563-2.307,1.314-3.166c-0.492-0.492-1.736-1.735-3.029-3.029C7.415,11.085,6.455,13.253,6.367,15.64z M1.364,15.64 h4.282c0.089-2.585,1.13-4.934,2.782-6.703C7.15,7.66,5.915,6.424,5.396,5.906C2.873,8.553,1.452,11.984,1.364,15.64z M9.445,8.938 c0.828,0.826,1.825,1.823,3.03,3.028c0.858-0.751,1.958-1.233,3.166-1.314V6.367C13.252,6.455,11.084,7.415,9.445,8.938z",
          onclick: () => changeChartType("sunburst"),
        },
        myTreemapSwitch: {
          show: true,
          title: "Switch to Treemap",
          icon: "m11 3h-7c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h7zm10 9.4h-8.6v8.6h7.6c.478 0 1-.379 1-1zm-8.6-9.4v8h8.6v-7c0-.478-.379-1-1-1z",
          onclick: () => changeChartType("treemap"),
        },
        dataView: {
          show: true,
          readOnly: true,
          optionToContent: (option: any) => {
            // returns the HTML convertion of JSX element
            function getLayerRows(
              data: Array<any>,
              preName: String
            ): React.ReactNode {
              if (!data.length) return null;
              return data.map((d, idx) => (
                <Fragment key={preName + "-" + idx}>
                  <tr className="border-b-[1px] border-border">
                    <td>{d.name}</td>
                    <td>{preName}</td>
                    <td>{d.value}</td>
                  </tr>
                  {getLayerRows(d.children, d.name)}
                </Fragment>
              ));
            }
            return ReactDOMServer.renderToStaticMarkup(
              <table className="text-center w-full">
                <tbody>
                  <tr className="border-b-[1px] border-border">
                    {["Name", "Group / Pre-meter", "Value"].map(
                      (header, idx) => (
                        <th className="w-1/3 font-medium" key={idx}>
                          {header}
                        </th>
                      )
                    )}
                  </tr>
                  {getLayerRows(option.series[0].data, "")}
                </tbody>
              </table>
            );
          },
        },
        saveAsImage: { show: true },
      },
    },
    series: sunburstSerie, // as defualt
  };

  function changeChartType(type: "sunburst" | "treemap") {
    const chartInstance = chartRef.current!.getEchartsInstance();
    const currentOptions = chartInstance.getOption() as EChartsOption;
    const currentData = (currentOptions.series as any[])[0].data;
    chartInstance.setOption({
      series: {
        ...(type === "sunburst" ? sunburstSerie : treemapSerie),
        data: currentData, // to prevent empty data array
      },
    });
  }

  return (
    <ReactECharts
      ref={chartRef}
      style={{ height: "100%", width: "100%" }}
      option={option}
    />
  );
};

export default memo(CircuitEnergyConsumptionChart);
